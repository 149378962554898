.big-title {
  font-size: 40px !important;
  padding-bottom: 10px;
}
    
.small-title {
  font-size: 32px !important;
  padding-bottom: 15px;
}
  
.user-page-container {
  margin: 20px; 
  
}

.info-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.info-grid {
  padding: 30px;
  padding-top: 0px;
}

.tutorial-link {
  color: #085474;
  padding-bottom: 40px;
}
