.big-title {
  font-size: 40px !important;
  padding-bottom: 10px;
}
    
.small-title {
  font-size: 32px !important;
  padding-bottom: 15px;
}
  
.org-page-container {
  margin: 20px; 
  
}

.info-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.info-grid {
  padding: 30px;
  padding-top: 0px;
}

.tutorial-link {
  color: #085474;
  padding-bottom: 40px;
}

.giving-table {
    width: 450px !important;
    border-bottom: none;
}

.dropdown-arrow {
  cursor: pointer;
  margin-left: 2px;
  margin-right: 6px;
  vertical-align: middle;
}

.arrow {
  cursor: pointer;
  font-size: 24px;
  vertical-align: middle;
  margin-left: 8px;
}

.edit-buttons {
  margin-left: 16px;
  vertical-align: middle;
}